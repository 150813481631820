<template>
    <div>
        <nav class="navbar fixed-top containers-header">
            <div class="container-fluid">
                <span v-if="route.meta.title" class="navbar-brand h2 my-auto mx-2" @click="showVersionModal">
                    {{ $t(route.meta.title.toString()) || "" }}
                </span>
                <div class="d-flex bd-highlight">
                    <div v-if="impersonateCookie">
                        <button class="btn-icon me-3" @click="switchToImpersonator">
                            <font-awesome-icon icon="right-from-bracket" class="text-danger" />
                        </button>
                    </div>
                    <CommonPhoneSupport />
                    <NuxtLink
                        v-if="
                            authStatus === 'authenticated' &&
                            authData?.id &&
                            authData?.driver &&
                            authData?.driver.ciziTahac
                        "
                        :to="{ name: 'docs' }"
                        class="btn-icon me-3"
                        no-prefetch
                        ><font-awesome-icon icon="question-circle"
                    /></NuxtLink>

                    <div v-if="authStatus === 'authenticated' && authData?.id && authData?.driver" class="dropdown">
                        <button
                            id="dropdownMenuButton"
                            class="btn-icon"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <font-awesome-icon icon="ellipsis-v" />
                        </button>

                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                            <a
                                v-if="authData.driver && !authData.driver.ciziTahac"
                                class="dropdown-item"
                                @click="toggleDocumentationModal"
                                >{{ $t("label.help") }}</a
                            >
                            <a
                                v-if="store.activeRide && authData.driver && authData.driver.ciziTahac"
                                class="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#passTheRideModal"
                                >{{ $t("label.passTheRide.header") }}</a
                            >
                            <a
                                v-if="authData.driver && store.activeRide && !authData.driver.ciziTahac"
                                class="dropdown-item"
                                @click.prevent="synchronize"
                                >{{ $t("label.synchronize") }}</a
                            >
                            <a
                                v-if="
                                    store.activeRide &&
                                    authData.driver &&
                                    authData.driver.ciziTahac &&
                                    (store.activeRide.ID_STATUS === 1 || store.activeRide.ID_STATUS === 5)
                                "
                                class="dropdown-item"
                                @click.prevent="cancelTrip"
                                >{{ $t("label.cancelTrip") }}</a
                            >
                            <a
                                v-if="
                                    store.activeRide &&
                                    ((store.activeRide.ID_STATUS === 1 &&
                                        store.activeRide.SLOTS.some((slot) => slot.DIRECTION === 'P')) ||
                                        (store.activeRide.ID_STATUS === 5 &&
                                            store.activeRide.SLOTS.some((slot) => slot.DIRECTION === 'D')))
                                "
                                class="dropdown-item"
                                @click.prevent="cancelSlot"
                                >{{ $t("label.cancelSlot") }}</a
                            >
                            <a
                                v-if="store.activeRide && !authData.driver.ciziTahac"
                                class="dropdown-item"
                                @click.prevent="interruptRide"
                                >{{ $t("actions.interruptRide") }}</a
                            >
                            <NuxtLink class="dropdown-item" :to="{ name: 'driver-profile' }">{{
                                $t("settings.header")
                            }}</NuxtLink>

                            <a class="dropdown-item" @click.prevent="logout">{{ $t("auth.logout") }}</a>
                            <div class="dropdown-divider" />
                            <div>
                                <a id="displayVersion" class="dropdown-item dropdown-disabled" @click="showVersionModal"
                                    >{{ config.public.packageVersion }} | WS:
                                    <font-awesome-icon :icon="connectionStore.socket.connected ? 'check' : 'xmark'" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div v-if="route.meta.title === 'auth.header'" class="ms-0">
                        <font-awesome-icon icon="language" />
                        <select v-model="locale" @change="changeLanguage(locale)">
                            <option
                                v-for="localeItem in locales"
                                :key="localeItem.code"
                                :value="localeItem.code"
                                @click.prevent.stop="changeLanguage(localeItem.code)"
                            >
                                {{ localeItem.label }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </nav>

        <CommonVersionCheck ref="versionCheckModal" />
        <MobileRidePassTheRideModal />
        <CommonDocumentationComponent v-if="documentationModalShown" ref="documentationModal" no-prefetch />

        <!-- ANDROID/IOS NATIVE  -->
        <div v-if="Capacitor.isNativePlatform()">
            <MobileNativeApplicationHooks />
            <MobileNativeAppUpdater />
            <MobileNativeNotifications v-if="authStatus === 'authenticated'" />
            <MobileNativeLocationService v-if="store.activeRide" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { Capacitor } from "@capacitor/core";
import type DocumentationComponent from "~/components/common/DocumentationComponent.vue";
import type VersionCheck from "~/components/common/VersionCheck.vue";

const impersonateCookie = useCookie("auth.token.impersonator");
const { setToken } = useAuthState();

const { status: authStatus, data: authData } = useAuth();
const { locale, locales, t, setLocaleMessage, setLocaleCookie, setLocale } = useI18n();

const config = useRuntimeConfig();

const connectionStore = useConnectionStore();
const axios = useAxios();

const documentationModal = ref<InstanceType<typeof DocumentationComponent>>();
const versionCheckModal = ref<InstanceType<typeof VersionCheck>>();
const store = useStore();
const documentationModalShown = ref(false);
const route = useRoute();

async function logout() {
    connectionStore.socket.disconnect();
    await navigateTo({ name: "auth-logout" });
}
async function loadLocaleMessages(locale: string) {
    await axios.get(`/api/language/${locale}`).then((response) => {
        setLocaleMessage(locale, response.data);
    });
}

async function changeLanguage(selectedLang: string) {
    await loadLocaleMessages(selectedLang);
    setLocaleCookie(selectedLang);
    setLocale(selectedLang);
}

async function interruptRide() {
    // copy of VUEX object
    store.setLoading(true);
    const interruptedRide = JSON.parse(JSON.stringify(store.activeRide));
    interruptedRide.IS_INTERRUPTED = 1;
    if (store.activeRide) {
        await axios.patch("/api/ride", { ID: store.activeRide.ID, IS_INTERRUPTED: 1 }).then(async () => {
            await store.loadActiveRide();
        });
    }
}

watch(
    () => authStatus.value,
    (newValue) => {
        if (newValue === "authenticated") {
            changeLanguage(authData.value?.language || "cs");
        }
    }
);

// async function flagAsRefused() {
//     if (!confirm(t("label.areYouSure").toString())) {
//         return;
//     }
//     if (!store.activeRide) {
//         return;
//     }
//     store.setLoading(true);

//     await axios.patch("/api/ride/" + store.activeRide.ID + "/refused").then(async () => {
//         await store.loadActiveRide();
//     });
// }

async function cancelTrip() {
    if (!confirm(t("label.areYouSure").toString())) {
        return;
    }
    // check existing slots if cancel ride
    if (!store.activeRide || !store.activeRide.ID) {
        return;
    }
    store.setLoading(true);
    if (store.activeRide.SLOTS.length > 0) {
        await cancelSlot();
        connectionStore.socket.emit("slot.remove", store.activeRide);
    }
    if (store.connectingRide && store.connectingRide.ID) {
        await axios.patch("/api/ride/", {
            ID: store.connectingRide.ID,
            ID_STATUS: 7
        });
        connectionStore.socket.emit("ride.remove", store.connectingRide);
    }
    await axios
        .patch("/api/ride/", {
            ID: store.activeRide.ID,
            ID_STATUS: 7
        })
        .then(async () => {
            connectionStore.socket.emit("ride.remove", store.activeRide);
        });

    await store.loadActiveRide();
}

async function cancelSlot() {
    if (!store.activeRide) {
        return;
    }
    store.setLoading(true);
    const active =
        store.activeRide.ID_STATUS === 1
            ? store.activeRide.SLOTS.find((slot) => slot.DIRECTION === "P")
            : store.activeRide.ID_STATUS === 5
              ? store.activeRide.SLOTS.find((slot) => slot.DIRECTION === "D")
              : null;
    if (active) {
        await axios.delete(`api/slot/${active.GATE_CODE}/cancel`);

        connectionStore.socket.emit("slot.remove", store.activeRide);
        await store.loadActiveRide();
    }
}

function synchronize() {
    if (!store.activeRide) {
        return;
    }
    // axios.patch("/api/ride/" + store.activeRide.ID + "/synchronize").then(async () => {
    store.loadActiveRide();
    // });
}

onMounted(() => {
    //
    // PWA removal fix for capacitor
    // Must be present due to https://capacitorjs.com/docs/android/troubleshooting#plugin-not-implemented
    //
    if (window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations().then(async (registrations) => {
            for (const registration of registrations) {
                await registration.unregister();
            }
            if (registrations && registrations.length) {
                if ("caches" in window) {
                    const cacheNames = await caches.keys();
                    await Promise.all(
                        cacheNames.map(async (cacheName) => {
                            await caches.delete(cacheName);
                        })
                    );
                }
                location.reload();
            }
        });
    }
    if (authData.value?.language) {
        changeLanguage(authData.value?.language);
    }
});

function showVersionModal() {
    if (route.meta.title === "settings.header" || route.meta.title === "auth.header")
        versionCheckModal.value?.showModal();
}

function toggleDocumentationModal() {
    documentationModalShown.value = !documentationModalShown.value;

    nextTick(() => {
        if (documentationModalShown.value) {
            documentationModal.value?.showModal();
            documentationModal.value?.onOpenedEvent(() => {
                documentationModalShown.value = true;
            });
        }
    });
}

function switchToImpersonator() {
    if (impersonateCookie) {
        const impersonateCookie = useCookie<string | null>("auth.token.impersonator");

        setToken(impersonateCookie.value);
        impersonateCookie.value = null;
        nextTick(() => {
            window.location.reload();
        });
    }
}
</script>
